import React from 'react';
import './Icons.scss';
import classNames from 'classnames';

export type IconModifier =
	| 'arrow-right'
	| 'arrow-left'
	| 'close'
	| 'eye-open'
	| 'watch'
	| 'truck'
	| 'overview'
	| 'google'
	| 'facebook'
	| 'info'
	| 'eye-close'
	| 'adjust'
	| 'chevron-down'
	| 'chevron-up'
	| 'chevron-right'
	| 'chevron-left'
	| 'user'
	| 'settings'
	| 'logout'
	| 'attention'
	| 'phone'
	| 'bell'
	| 'gas'
	| 'note'
	| 'perennial'
	| 'e-invoice'
	| 'cancel-e-invoice'
	| 'search'
	| 'plus'
	| 'file'
	| 'plant'
	| 'attachment'
	| 'star'
	| 'bigstar';

interface IconsProps {
	modifier?: IconModifier;
}

function Icons({ modifier = 'arrow-right', ...props }: IconsProps) {
	return <i className={classNames(['icon', modifier && `icon--${modifier}`])} {...props}></i>;
}

export default React.memo(Icons);
