import { create } from 'zustand';
import { JwtToken } from '@core/models/jwt-token.model';
import { persist } from 'zustand/middleware';
import { UserProfile } from '@core/models/user-profile.model';

interface AuthStore {
	jwtToken: JwtToken | null;
	login: (jwtToken: JwtToken) => void;
	logout: () => void;
}

export const useAuthStore = create<AuthStore>()(
	persist(
		(set) => ({
			jwtToken: null,
			login: (jwtToken) => set({ jwtToken }),
			logout: () => set({ jwtToken: null }),
		}),
		{ name: 'auth' },
	),
);

interface UserStore {
	user: UserProfile | null;
	setUser: (user: UserProfile) => void;
	setCompletedSurvey: () => void;
	removeUser: () => void;
}

export const useUserStore = create<UserStore>((set) => ({
	user: null,
	setUser: (user) => set({ user }),
	setCompletedSurvey: () =>
		set((store) => ({
			user: { ...store.user, completed_survey: 1 } as UserProfile,
		})),
	removeUser: () => set({ user: null }),
}));
