import { create } from 'zustand';

interface SurveyStore {
	isSurveyOpen: boolean;
	setIsSurveyOpen: (value: boolean) => void;
}

export const useSurveyStore = create<SurveyStore>((set) => ({
	isSurveyOpen: false,
	setIsSurveyOpen: (value) => set({ isSurveyOpen: value }),
}));
